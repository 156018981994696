.galleryText {
    color: #333333;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    margin-top: 40px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 2px;
    margin-bottom: 30px;
    text-align: center;
  }
  hr{
    width: 100%;
  }
  .galleryimageContainer { 
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin-bottom: 40px;
  }
  .galleryImage {
    width: 100%;
    height: 100%; 
  }
  .galleryborderBottom {
    background-color: white !important;
  }
  
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px !important;
    margin-bottom: 40px; 
  }
  
  .column {
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  } 
  
  .ant-modal-close-icon svg {
    color: white !important;
  }
  
  .ant-modal-body {
    padding: 0 !important;
  }
  .fade{
    opacity: 1 !important;
  }
  
  .modalCss .ant-modal-content {
    background-color: transparent !important;
  }
  
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
    .galleryText {
      font-size: 33px; 
    }
  }
  
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
    .galleryText {
      font-size: 28px;
      line-height: 20px;
    }
  } 
  .react-slideshow-container .default-nav{
    background: none;
    border: none;
  }
  .react-slideshow-container .default-nav:focus{
    background: none;
    border: none;
  }
  .react-slideshow-container .default-nav:hover{
    background-color: #e2af46;
  }
  .content_e296pg{
    width: 100%;
  }
  .img_pc9x2k-o_O-imgLoaded_9vayoh{
    width: 100%;
  }
  .thumbs-wrapper{
    display: none;
  }