.country{
  &:hover{
    background-color: rgba(50, 50, 50, 0.9) !important;
    color: #fff !important;
  }
}
.highlight {
    background-color: #FFBA83 !important;
    color: #000 !important;
    &:hover{
      background-color: rgb(255, 171, 116) !important;
      color: #000 !important;
    }
}
.country-list {
    width: 200px;
    height: 160px;
    overflow: scroll;
    margin-top: -3px !important;
    &::-webkit-scrollbar {
      width: 0;
    }
}
.selected-flag {
  background-color: rgb(30, 35, 36) !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
@media (max-width: 600px) {
    .country-list {
      width: calc(100% - 35px) !important;
    }
  }