@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
body {
  margin: 0;
  font-family: "Inter !important";
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(19,30,34);
  background: linear-gradient(0deg, rgba(19,30,34,1) 0%, rgba(14,14,14,1) 60%);
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: "textfield";
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
